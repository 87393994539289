import React from "react";
import { Routes, Route } from "react-router-dom";
import ThankYouPage from "./components/ThankYouPage";
import HomePage from "./components/HomePage";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/agradecimento" element={<ThankYouPage />} />
    </Routes>
  );
}
export default App;
