import React, { useState } from "react";
import SubscribeModal from "./SubscribeModal";

interface PricingCardProps {
  title: string;
  description: string;
  originalPrice: string;
  discount: string;
  currentPrice: string;
  renewalPrice: string;
  features: string[];
  buttonText: string;
  idTipoInscription: number;
  valorInscription: number;
  idadeMinima: number; 
  idadeMaxima: number;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  description,
  originalPrice,
  discount,
  currentPrice,
  renewalPrice,
  features,
  buttonText,
  idTipoInscription,
  valorInscription,
  idadeMinima,
  idadeMaxima,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="bg-gray-100 p-10 mb-5 rounded-lg shadow-lg w-full md:w-1/2 lg:w-1/4 mx-auto">
      <div className="h-pricing-card__content-stretcher">&nbsp;</div>
      <div className="text-2xl font-bold mb-4 text-center max-w-md mx-auto">{title}</div>
      <div className="text-gray-600 text-sm mb-4 text-center max-w-md mx-auto font-semibold">{description}</div>
      <div className="flex items-center mb-4">
        <span className="text-gray-600 text-sm line-through">
          R$&nbsp;{originalPrice}
        </span>
        <div className="bg-yellow-500 text-white px-2 py-1 rounded-md ml-2">
          <span className="text-sm font-bold">{discount}</span>
        </div>
      </div>
      <div className="text-4xl mb-4">
        <span className="text-gray-800">R$&nbsp;{currentPrice}</span>
        <span className="text-sm text-gray-600">/{title}*</span>
      </div>

      <section id="signup" className="p-0">
        <div className="text-center py-0">
          <button 
            onClick={() => setIsModalOpen(true)}
            className="bg-green-500 text-white px-4 py-2 rounded-md mb-4 w-full"
          >
            {buttonText}
          </button>
        </div>
        <SubscribeModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)} 
          idTipoInscription={idTipoInscription} 
          valorInscription={valorInscription} 
          idadeMinima={idadeMinima} 
          idadeMaxima={idadeMaxima}
        />
      </section>

      <div className="border-t border-gray-300 my-4"></div>
      <div className="mb-4">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 16 16"
              className="mr-2"
            >
              <g>
                <path
                  fill="#00b090"
                  d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                ></path>
              </g>
            </svg>
            <div className="text-sm text-gray-600">{feature}</div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default PricingCard;
