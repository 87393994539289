import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redireciona para a página inicial após 5 segundos
    const timer = setTimeout(() => {
      navigate('/');
    }, 10000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-600">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md mx-4">
        <h1 className="text-4xl font-bold text-purple-800 mb-4">Obrigado!</h1>
        <p className="text-gray-700 mb-6">
          Agradecemos sua inscrição no evento de Carnaval da Capela do Sol. Que a luz
          espiritual guie seus passos e encha seu coração de paz e alegria.
        </p>
        <p className="text-gray-600 italic">
          "A caridade é o caminho para a evolução. Que você continue a espalhar amor e
          bondade por onde passar."
        </p>
      </div>
    </div>
  );
};

export default ThankYouPage;