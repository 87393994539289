import React, { useState } from "react";
import MoreInfo from "./MoreInfo";
import axios from "axios";

const ContactSection: React.FC = () => {
  const contacts = [
    { name: "Carla", phone: "+55 (31) 99623-0379" },
    { name: "Maya", phone: "+55 (31) 99629-3844" },
    { name: "Raquel", phone: "+55 (31) 98449-5047" },
    { name: "Zileia", phone: "+55 (31) 99139-9050" },
  ];

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  //const urlLocal = 'http://localhost:4000/mail/send';
  const url = 'https://www.capeladosol.com.br/mail/send';

  const handleSendEmail = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Previne o comportamento padrão do formulário

    try {
      const response = await axios.post(url, {
        email,
        name,
        message,
      });

      if (response.data.status === 200) {
        setStatusMessage("E-mail enviado com sucesso!");
        setIsError(false);
        setEmail("");
        setName("");
        setMessage("");
      } else {
        setStatusMessage("Erro ao enviar e-mail.");
        setIsError(true);
      }
    } catch (error) {
      console.error("Erro ao enviar e-mail:", error);
      setStatusMessage("Erro ao enviar e-mail.");
      setIsError(true);
    }
  };

  return (
    <section id="contact" className="p-4">
      <h2 className="text-4xl font-bold text-center mb-4 px-4">Contato</h2>
      <p className="text-lg font-bold text-left py-6">
        Alguma dúvida sobre o evento? Envie sua pergunta abaixo e responderemos o
        mais breve possível.
      </p>

      {/* Mensagem de status */}
      {statusMessage && (
        <div
          className={`p-4 mb-4 rounded-lg ${
            isError ? "bg-red-100 text-red-700" : "bg-green-100 text-green-700"
          }`}
        >
          {statusMessage}
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-8">
        {/* Formulário */}
        <form
          action="mailto:encontroespiritasl@gmail.com"
          method="post"
          encType="text/plain"
          className="flex-1 space-y-4 bg-gray-100 p-4"
        >
          <label
            htmlFor="name"
            className="block text-lg font-bold text-gray-700"
          >
            Nome
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 text-sm"
            required
          />

          <label
            htmlFor="email"
            className="block text-lg font-bold text-gray-700"
          >
            E-mail:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 text-sm"
            required
          />

          <label
            htmlFor="message"
            className="block text-lg font-bold text-gray-700"
          >
            Mensagem:
          </label>
          <textarea
            id="message"
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 text-sm"
            required
          />

          <button
            onClick={handleSendEmail}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Enviar
          </button>
        </form>

        {/* Mais Informações */}
        <section
          id="moreinfo"
          className="flex-1 bg-gray-100 p-8 rounded-lg shadow-md"
        >
          <MoreInfo title="Mais Informações:" contacts={contacts} />
        </section>
      </div>
    </section>
  );
};

export default ContactSection;