import React, { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { InputMask } from "@react-input/mask";
import PixPaymentForm from "./PixPaymentForm";
import { SubscriberDto } from "./subscriberDto";
import { useNavigate } from "react-router-dom";

interface SubscribeModalProps {
  isOpen: boolean;
  idTipoInscription: number;
  valorInscription: number;
  idadeMinima: number;
  idadeMaxima: number;
  onClose: () => void;
}

interface ParticipantPix {
  email: string;
  fullName: string;
  value: number;
  subscribeID: number;
}

interface Participant {
  name: string;
  email: string;
  phone: string;
  cep: string;
  rua: string;
  numero: string;
  bairro: string;
  cidade: string;
  estado: string;
  pais: string;
  restrictions: string[];
  otherRestriction: string;
  casaEspirita: string;
  idade: string;
  tipoParticipante: string;
}

const SubscribeModal: React.FC<SubscribeModalProps> = ({
  isOpen,
  onClose,
  idadeMinima,
  idadeMaxima,
  idTipoInscription,
  valorInscription,
}) => {
  //const SubscribeModal: React.FC<SubscribeModalProps> = ({ isOpen, onClose }) => {
  const [currentParticipant, setCurrentParticipant] = useState<Participant>({
    name: "",
    email: "",
    phone: "",
    cep: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "Brasil",
    restrictions: [],
    otherRestriction: "",
    casaEspirita: "", // Novo campo
    idade: "",
    tipoParticipante: "",
  });
  /* const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("Brasil");
  const [restrictions, setRestrictions] = useState<string[]>([]);
  const [otherRestriction, setOtherRestriction] = useState("");
  const [casaEspirita, setCasaEspirita] = useState(""); // Novo campo
  const [idade, setIdade] = useState(""); // Novo campo
  const [tipoParticipante, setTipoParticipante] = useState(""); // Novo campo */
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [participantPIX, setParticipantPIX] = useState<ParticipantPix>({
    email: "",
    fullName: "",
    value: 0,
    subscribeID: 0,
  });

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setCurrentParticipant({
        ...currentParticipant,
        restrictions: [...currentParticipant.restrictions, value],
      });
    } else {
      setCurrentParticipant({
        ...currentParticipant,
        restrictions: currentParticipant.restrictions.filter(
          (restriction) => restriction !== value
        ),
      });
    }
  };

  /*  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setRestrictions([...restrictions, value]);
    } else {
      setRestrictions(
        restrictions.filter((restriction) => restriction !== value)
      );
    }
  }; */

  //const isOtherSelected = restrictions.includes("outro");
  const isOtherSelected = currentParticipant.restrictions.includes("outro");

  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //console.log("Inscrição enviada:", currentParticipant);
    try {
      const errors = validateParticipant(currentParticipant);
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      //console.log("Inscrição validada:", currentParticipant);

      // Requisição para enviar a inscrição
      await axios
        .post("https://capeladosol.com.br/subscriber", currentParticipant)
        //.post("http://localhost:4000/subscriber", currentParticipant)
        .then((response: AxiosResponse<SubscriberDto>) => {
          //console.log(response);
          const { email, name, id } = response.data;
          //console.log(email, name, id);

          //console.log("Idade:", idadeMaxima);
          if (idadeMaxima <= 10) {
            navigate("/agradecimento");
          }

          participantPIX.email = email;
          participantPIX.fullName = name;
          participantPIX.value = valorInscription;
          participantPIX.subscribeID = id;
        })
        .catch(function (error) {
          console.log(error);
        });
      //console.log("PIX:", participantPIX);
      handleNextStep();
      // Limpar o formulário após a inscrição
      /* setCurrentParticipant({
          name: "",
          email: "",
          phone: "",
          cep: currentParticipant.cep,
          rua: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          pais: "Brasil",
          restrictions: [],
          otherRestriction: "",
          casaEspirita: "",
          idade: "",
          tipoParticipante: "",
        });
    
        currentParticipant.name = "";
        currentParticipant.email = "";
        currentParticipant.phone = "";
        currentParticipant.cep = "";
        currentParticipant.rua = "";
        currentParticipant.numero = "";
        currentParticipant.bairro = "";
        currentParticipant.cidade = "";
        currentParticipant.estado = "";
        currentParticipant.restrictions = [];
        currentParticipant.otherRestriction = "";
        currentParticipant.casaEspirita = "";
        currentParticipant.idade = "";
        currentParticipant.tipoParticipante = ""; */
      //onClose();
    } catch (error) {
      console.error("Erro ao enviar inscrição:", error);
    }
  };

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone: string) => {
    const regex = /^\+55 \(\d{2}\) \d{5}-\d{4}$/;
    return regex.test(phone);
  };

  const validateCep = (cep: string) => {
    const regex = /^\d{2}\.\d{3}-\d{3}$/;
    return regex.test(cep);
  };

  const validateParticipant = (participant: Participant) => {
    const errors: { [key: string]: string } = {};

    if (!participant.name) errors.name = "Nome é obrigatório.";
    if (!validateEmail(participant.email)) errors.email = "E-mail inválido.";
    if (!validatePhone(participant.phone)) errors.phone = "Telefone inválido.";
    if (!validateCep(participant.cep)) errors.cep = "CEP inválido.";
    if (!participant.rua) errors.rua = "Rua é obrigatória.";
    if (!participant.bairro) errors.bairro = "Bairro é obrigatório.";
    if (!participant.cidade) errors.cidade = "Cidade é obrigatória.";
    if (!participant.estado) errors.estado = "Estado é obrigatório.";
    /* if (!participant.casaEspirita)
      errors.casaEspirita = "Casa Espírita é obrigatória."; */
    if (!participant.idade) errors.idade = "Idade é obrigatória.";
    if (!participant.tipoParticipante)
      errors.tipoParticipante = "Tipo de Participante é obrigatório.";

    return errors;
  };

  const fetchAddress = async (cep: string) => {
    try {
      const novoCep = cep.replace(/[.-]/g, "");
      let url = "";
      if (novoCep.length === 8) {
        url = `https://viacep.com.br/ws/${novoCep}/json/`;
      }
      const response = await axios.get(url);
      const data = response.data;
      /*setCep(cep);
      setRua(data.logradouro);
      setBairro(data.bairro);
      setCidade(data.localidade);
      setEstado(data.uf);*/
      setCurrentParticipant({
        ...currentParticipant,
        cep: cep,
        rua: data.logradouro,
        bairro: data.bairro,
        cidade: data.localidade,
        estado: data.uf,
      });
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };

  /*   useEffect(() => {
    if (cep.length === 9) {
      fetchAddress(cep);
    }
  }, [cep]); */

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-screen">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Formulário de Inscrição
        </h2>
        {step === 1 && (
          <>
            {/* <h2 className="text-xl font-bold mb-4 text-center">
              {idTipoInscription === 0
                ? `Adultos e Adolescentes - Valor: ${valorInscription.toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}`
                : `Crianças - Valor: ${valorInscription.toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}`}
            </h2>{" "} */}
            <h2 className="text-xl font-bold mb-4 text-center">
              {idTipoInscription === 0
                ? `Crianças até 10 anos - Valor: ${valorInscription.toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}`
                : idTipoInscription === 1
                ? `Crianças entre 11 e 12 - Valor: ${valorInscription.toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}`
                : `Adultos e Adolescentes - Valor: ${valorInscription.toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}`}
            </h2>{" "}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome
                </label>
                <input
                  type="text"
                  id="name"
                  //value={name}
                  //onChange={(e) => setName(e.target.value)}
                  value={currentParticipant.name}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      name: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  //value={email}
                  //onChange={(e) => setEmail(e.target.value)}
                  value={currentParticipant.email}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      email: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Telefone
                </label>
                <InputMask
                  mask="+55 (__) _____-____"
                  placeholder="(__) _____-____"
                  replacement={{ _: /\d/ }}
                  //value={phone}
                  //onChange={(e: any) => setPhone(e.target.value)}
                  value={currentParticipant.phone}
                  onChange={(e: any) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      phone: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="cep"
                  className="block text-sm font-medium text-gray-700"
                >
                  CEP
                </label>
                <InputMask
                  mask="__.___-___"
                  placeholder="__.___-___"
                  replacement={{ _: /\d/ }}
                  //value={cep}
                  /*onChange={(e: any) => {
                setCep(e.target.value);
                if (e.target.value.length === 10) fetchAddress(e.target.value);
              }}*/
                  value={currentParticipant.cep}
                  onChange={(e: any) => {
                    setCurrentParticipant({
                      ...currentParticipant,
                      cep: e.target.value,
                    });
                    if (e.target.value.length === 10)
                      fetchAddress(e.target.value);
                  }}
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.cep && (
                  <p className="text-red-500 text-sm">{errors.cep}</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="rua"
                  className="block text-sm font-medium text-gray-700"
                >
                  Rua
                </label>
                <input
                  type="text"
                  id="rua"
                  //value={rua}
                  //onChange={(e) => setRua(e.target.value)}
                  value={currentParticipant.rua}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      rua: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.rua && (
                  <p className="text-red-500 text-sm">{errors.rua}</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="numero"
                  className="block text-sm font-medium text-gray-700"
                >
                  Número
                </label>
                <input
                  type="text"
                  id="numero"
                  //value={numero}
                  //onChange={(e) => setNumero(e.target.value)}
                  value={currentParticipant.numero}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      numero: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.numero && (
                  <p className="text-red-500 text-sm">{errors.numero}</p>
                )}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="bairro"
                  className="block text-sm font-medium text-gray-700"
                >
                  Bairro
                </label>
                <input
                  type="text"
                  id="bairro"
                  //value={bairro}
                  //onChange={(e) => setBairro(e.target.value)}
                  value={currentParticipant.bairro}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      bairro: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.bairro && (
                  <p className="text-red-500 text-sm">{errors.bairro}</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="cidade"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cidade
                </label>
                <input
                  type="text"
                  id="cidade"
                  //value={cidade}
                  //onChange={(e) => setCidade(e.target.value)}
                  value={currentParticipant.cidade}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      cidade: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.cidade && (
                  <p className="text-red-500 text-sm">{errors.cidade}</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="estado"
                  className="block text-sm font-medium text-gray-700"
                >
                  Estado
                </label>
                <input
                  type="text"
                  id="estado"
                  //value={estado}
                  //onChange={(e: any) => setEstado(e.target.value)}
                  value={currentParticipant.estado}
                  onChange={(e: any) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      estado: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.estado && (
                  <p className="text-red-500 text-sm">{errors.estado}</p>
                )}
              </div>

              {/* Novos campos */}
              <div className="mb-3">
                <label
                  htmlFor="casaEspirita"
                  className="block text-sm font-medium text-gray-700"
                >
                  Casa Espírita de Origem
                </label>
                <input
                  type="text"
                  id="casaEspirita"
                  //value={casaEspirita}
                  //onChange={(e) => setCasaEspirita(e.target.value)}
                  value={currentParticipant.casaEspirita}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      casaEspirita: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  /*  required */
                />
                {errors.casaEspirita && (
                  <p className="text-red-500 text-sm">{errors.casaEspirita}</p>
                )}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="idade"
                  className="block text-sm font-medium text-gray-700"
                >
                  Idade do Participante
                </label>
                <input
                  type="number"
                  id="idade"
                  min={idadeMinima}
                  max={idadeMaxima}
                  //value={idade}
                  //onChange={(e) => setIdade(e.target.value)}
                  value={currentParticipant.idade}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      idade: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                />
                {errors.idade && (
                  <p className="text-red-500 text-sm">{errors.idade}</p>
                )}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="tipoParticipante"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tipo de Participante
                </label>
                <select
                  id="tipoParticipante"
                  //value={tipoParticipante}
                  //onChange={(e) => setTipoParticipante(e.target.value)}
                  value={currentParticipant.tipoParticipante}
                  onChange={(e) =>
                    setCurrentParticipant({
                      ...currentParticipant,
                      tipoParticipante: e.target.value,
                    })
                  }
                  className="w-full p-2 border border-gray-300 text-sm"
                  required
                >
                  <option value="">Selecione</option>
                  <option value="Confraternista">Confraternista</option>
                  <option value="Trabalhador">Trabalhador</option>
                </select>
                {errors.tipoParticipante && (
                  <p className="text-red-500 text-sm">
                    {errors.tipoParticipante}
                  </p>
                )}
              </div>

              <div>
                <div className="mb-3 p-2">
                  <label className="block text-lg font-bold py-3">
                    Restrições Alimentares
                  </label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value="gluten"
                        //checked={restrictions.includes("gluten")}
                        //onChange={handleCheckboxChange}
                        checked={currentParticipant.restrictions.includes(
                          "gluten"
                        )}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <label>Intolerância ao Glúten</label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value="lactose"
                        //checked={restrictions.includes("lactose")}
                        //onChange={handleCheckboxChange}
                        checked={currentParticipant.restrictions.includes(
                          "lactose"
                        )}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <label>Intolerância à Lactose</label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value="vegano"
                        //checked={restrictions.includes("vegano")}
                        //onChange={handleCheckboxChange}
                        checked={currentParticipant.restrictions.includes(
                          "vegano"
                        )}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <label>Vegano</label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value="vegetariano"
                        //checked={restrictions.includes("vegetariano")}
                        //onChange={handleCheckboxChange}
                        checked={currentParticipant.restrictions.includes(
                          "vegetariano"
                        )}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <label>Vegetariano</label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value="outro"
                        //checked={restrictions.includes("outro")}
                        //onChange={handleCheckboxChange}
                        checked={currentParticipant.restrictions.includes(
                          "outro"
                        )}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <label>Outro</label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="otherRestriction"
                    className="block text-lg font-medium text-gray-700"
                  >
                    Especifique outra restrição (se aplicável)
                  </label>
                  <input
                    type="text"
                    id="otherRestriction"
                    //value={otherRestriction}
                    //onChange={(e: any) => setOtherRestriction(e.target.value)}
                    value={currentParticipant.otherRestriction}
                    onChange={(e: any) =>
                      setCurrentParticipant({
                        ...currentParticipant,
                        otherRestriction: e.target.value,
                      })
                    }
                    className="w-full p-2 border border-gray-300 text-sm"
                    disabled={!isOtherSelected}
                  />
                </div>
              </div>

              <div className="flex justify-center space-x-4 mt-6">
                <button
                  type="submit"
                  className="bg-green-600 text-white px-6 py-3 rounded"
                >
                  Concluir
                </button>
                <button
                  onClick={onClose}
                  className="bg-blue-600 text-white px-6 py-3 rounded"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <PixPaymentForm
              email={participantPIX.email}
              fullName={participantPIX.fullName}
              value={participantPIX.value}
              subscribeID={participantPIX.subscribeID}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SubscribeModal;
