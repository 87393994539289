import React from 'react';

const SubHeader: React.FC = () => {
  return (
    <section id="showcase" className="min-h-70 bg-green-600 text-white text-center relative">
      <div className="container mx-auto py-10 px-4">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2">
          <strong>8º ENCONTRO DE EVANGELHO</strong>
        </h1>
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2">
          <strong>DAS CASAS ESPÍRITAS</strong>
        </h1>        
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
          <strong>DE SETE LAGOAS E REGIÃO</strong>
        </h1>        
        <p className="text-base md:text-lg">
          Sete Lagoas, Minas Gerais
        </p>
        <p className="text-base md:text-lg mb-8">
          <strong>De 01/03/2025 A 04/03/2025</strong>
        </p>

{/*         <div className="text-center">
          <a href="#signup" className="bg-blue-700 text-white px-6 py-3 rounded-lg">
            Inscreva-se Agora
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default SubHeader;