import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

interface PixPaymentFormProps {
  email: string;
  fullName: string;
  value: number;
  subscribeID: number;
}

const PixPaymentForm = (pixPaymentFormProps: PixPaymentFormProps) => {
  const [email, setEmail] = useState(pixPaymentFormProps.email);
  const [fullName, setFullName] = useState(pixPaymentFormProps.fullName);
  const [value, setValue] = useState(pixPaymentFormProps.value);
  const [pixQrCode, setPixQrCode] = useState("");
  const [pixCode, setPixCode] = useState("");
  const [idPagamento, setidPagamento] = useState("");
  const [idPagto, setidPagto] = useState(0);
  const [subscribeID] = useState(pixPaymentFormProps.subscribeID);;
  const [isCopied, setIsCopied] = useState(false);
  const [contaRegerar, setContaRegerar] = useState(0);
  const navigate = useNavigate();
  // Estado para armazenar o tempo restante em segundos
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutos = 300 segundos

  const processCreatePayment = async (idPagto:number) => {
    try {
      //console.log('idPagto'+idPagto)
      setidPagto(idPagto);
      const response = await fetch(
        `https://www.capeladosol.com.br/payments/create-payment/`, {
        //`http://localhost:4000/payments/create-payment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_id: idPagto,
          subscribeID: subscribeID,
          transaction_amount: value,
          status: "pending",
        }),
      });
      //console.log('idPagamento'+idPagamento) 
      //console.log(response);
      const result = await response.json();
      //console.log("Subscription update result:", result);
  
      if (response.ok) {
        console.log("Subscription updated successfully.");
      } else {
        console.error("Failed to update subscription:", result.message);
        alert("Erro ao atualizar a inscrição. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao atualizar a inscrição:", error);
      alert("Erro ao atualizar a inscrição. Tente novamente.");
    }
  };
  
  const processPayment = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const paymentResponse = await fetch(
        //"http://localhost:4000/payments/process-payment",
        'https://www.capeladosol.com.br/payments/process-payment',
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            fullName,
            value,            
          }),
        }
      );

      const paymentResult = await paymentResponse.json();
      //console.log("Resultado do pagamento:", paymentResult);
      if (paymentResult.status === "pending") {
        setTimeLeft(300);
        //console.log("id do pagamento:", paymentResult.id);
        setidPagamento(paymentResult.id);
        processCreatePayment(paymentResult.id);
        setPixQrCode(
          paymentResult.point_of_interaction.transaction_data.qr_code_base64
        );
        setPixCode(paymentResult.point_of_interaction.transaction_data.qr_code);
      } else {
        alert("Erro ao gerar PIX. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao processar pagamento:", error);
      alert("Erro ao processar pagamento. Tente novamente.");
    }
  };

  // Função para formatar o tempo no formato MM:SS
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Efeito para iniciar o contador
  useEffect(() => {
    if (timeLeft === 0) {
      setPixQrCode(""); // Limpa o pixQrCode quando o tempo chega a zero
      setPixCode(""); // Limpa o pixCode quando o tempo chega a zero
      setContaRegerar(contaRegerar + 1);
      return; // Para o contador
    } // Para o contador quando chegar a zero

    // Cria um intervalo que decrementa o tempo a cada segundo
    const interval =  setInterval(async () => {
      try { 
         const paymentStatus = await fetch(
          //`http://localhost:4000/payments/pagamento/${idPagto}`,
          `https://www.capeladosol.com.br/payments/pagamento/${idPagto}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
          );
          //console.log('idPagto+paymentStatus'+idPagto+' '+ paymentStatus)
          const paymentStatusJson = await paymentStatus.json();
          if (paymentStatusJson.statusCode === 200) {  
            navigate('/agradecimento'); // Redireciona para a página de agradecimento
          }
        } catch (error) {
          console.error("Erro na Leitura do pagamento:", error);
        }

      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Limpa o intervalo quando o componente é desmontado ou o tempo chega a zero
    return () => clearInterval(interval);
  }, [timeLeft]);

  const handleCopyPixCode = () => {
    navigator.clipboard
      .writeText(pixCode)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((error) => {
        //console.error("Erro ao copiar código PIX:", error);
        alert("Erro ao copiar código PIX. Tente novamente.");
      });
  };

  return (
    <div className="container mx-auto p-4 max-w-md bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold text-center mb-6">Pagamento via PIX</h1>
      <form onSubmit={processPayment} className="space-y-4">
        <div>
          <label
            htmlFor="email"
            className="hidden text-sm font-medium text-gray-700"
          >
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={pixPaymentFormProps.email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 hidden  w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label
            htmlFor="fullName"
            className="hidden  text-sm font-medium text-gray-700"
          >
            Nome Completo:
          </label>
          <input
            type="text"
            id="fullName"
            value={pixPaymentFormProps.fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="mt-1 hidden  w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label
            htmlFor="value"
            className="hidden text-sm font-medium text-gray-700"
          >
            Valor:
          </label>
          <input
            type="number"
            id="value"
            value={pixPaymentFormProps.value}
            onChange={(e) => setValue(Number(e.target.value))}
            className="hidden mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        {!pixQrCode && contaRegerar === 0 && ( // Renderiza o botão somente se pixQrCode não existir ou estiver vazio
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Gerar PIX
          </button>
        )} {!pixQrCode && contaRegerar !== 0 && (// Renderiza o botão somente se pixQrCode não existir ou estiver vazio
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Regera o PIX - Somente se não tiver pago
          </button>)}
      </form>
      {pixQrCode && (
        <div className="mt-6 text-center">
        <div className="text-center">
          {timeLeft === 0 ? ( // Verifica se o tempo chegou a zero
            <p className="text-lg font-semibold text-red-600">PIX Expirado</p>
          ) : (
            <>
              <p className="text-lg font-semibold">Tempo Restante:</p>
              <p className="text-3xl font-bold text-blue-600">{formatTime(timeLeft)}</p>
            </>
          )}
        </div>
          <h2 className="text-xl font-bold mb-4">QR Code PIX</h2>
          <img
            src={`data:image/jpeg;base64,${pixQrCode}`}
            alt="QR Code PIX"
            className="mx-auto w-48 h-48 border border-gray-300 rounded-lg"
          />
          <div className="mt-4">
            <p className="text-gray-700 break-all">Código PIX: {pixCode}</p>
            <button
              onClick={handleCopyPixCode}
              className="mt-2 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
            >
              {isCopied ? "Copiado!" : "Copiar Código PIX"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PixPaymentForm;
