import React from "react";

const ScheduleTable: React.FC = () => {
  return (
    <div className="overflow-x-auto md:overflow-x-hidden pt-10 pb-10">
      <table className="min-w-full border border-gray-300 shadow-md rounded-lg">
        <thead className="bg-gray-700 text-white">
          <tr>
            <th className="py-3 px-4 border-r border-gray-300 text-center">Data</th>
            <th className="py-3 px-4 border-r border-gray-300 text-center">Horário</th>
            <th className="py-3 px-4 border-r border-gray-300">Tema</th>
            <th className="py-3 px-4 border-r border-gray-300">Subtemas</th>
            <th className="py-3 px-4">Exemplo</th>
          </tr>
        </thead>
        <tbody className="text-gray-800   bg-zinc-100 font-book-antiqua font-semibold text-xl">
          {/* Sábado */}
          <tr>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-left">Sábado 01/03</td>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-center">14:00 às 17:00</td>
            <td className="py-3 px-4 border-r border-b border-gray-300">Abertura - O que é fé?</td>
            <td className="py-3 px-4 border-r border-b border-gray-300"></td>
            <td className="py-3 px-4 border-b border-gray-300"></td>
          </tr>
          {/* Domingo */}
          <tr>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-left">Domingo 02/03</td>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-center">08:00 às 17:00</td>
            <td className="py-3 px-4 border-r border-b border-gray-300">Entendimento do que é fé</td>
            <td className="py-3 px-4 border-r border-b border-gray-300">
              <ul className="list-disc pl-5">
                <li>Fé cega e fé raciocinada</li>
                <li>Fé humana e fé divina</li>
                <li>A Fé na vida futura</li>
              </ul>
            </td>
            <td className="py-3 px-4 border-b border-gray-300">Divaldo Pereira Franco</td>
          </tr>
          {/* Segunda-Feira */}
          <tr>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-left">Segunda-Feira 03/03</td>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-center">08:00 às 17:00</td>
            <td className="py-3 px-4 border-r border-b border-gray-300">Reconhecendo nossa fé</td>
            <td className="py-3 px-4 border-r border-b border-gray-300">
              <ul className="list-disc pl-5">
                <li>Fé e Resiliência em Tempos de Adversidade</li>
                <li>Autoconhecimento e a Vivência da Fé no Cotidiano</li>
                <li>
                  Reconhecimento de Nossa Fé pela avaliação de nossas Ações e
                  Reações
                </li>
              </ul>
            </td>
            <td className="py-3 px-4 border-b border-gray-300">Ivone Pereira do Amaral</td>
          </tr>
          {/* Terça-Feira */}
          <tr>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-left">Terça-Feira 04/03</td>
            <td className="py-3 px-4 border-r border-b border-gray-300 text-center">08:00 às 15:00</td>
            <td className="py-3 px-4 border-r border-b border-gray-300">
              Como melhorar, aumentar, construir a fé - Recursos e ferramentas
            </td>
            <td className="py-3 px-4 border-r border-b border-gray-300">
              <ul className="list-disc pl-5">
                <li>Estudo Contínuo: Fundamento da Fé</li>
                <li>Práticas Espirituais Diárias: Alimentando e Cultivando a Fé</li>
              </ul>
            </td>
            <td className="py-3 px-4 border-b border-gray-300">Jerônimo Mendonça</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleTable;